@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content_box {
    padding: toRem(10) toRem(12);
    .content {
        background-color: #fff;
        border-radius: toRem(12);
        padding: 0 toRem(12);
        overflow: hidden;
        .status {
            margin-top: toRem(34);
            display: flex;
            flex-direction: column;
            align-items: center;
            padding-bottom: toRem(30);
            border-bottom: toRem(1) solid #e9e9e9;
            .line {
                display: flex;
                align-items: center;
                margin-bottom: toRem(17);
                div:nth-child(1),
                div:nth-child(3),
                div:nth-child(5) {
                    width: toRem(16);
                    height: toRem(16);
                    border-radius: 50%;
                    border: toRem(2) solid #ff5a01;
                }
                div:nth-child(2),
                div:nth-child(4) {
                    width: toRem(68);
                    height: toRem(1);
                    margin: 0 toRem(14);
                    background-color: #ff5a01;
                }
            }
            .text {
                display: flex;
                justify-content: space-between;
                div {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    font-size: toRem(14);
                    margin-bottom: toRem(3);
                    font-weight: bold;
                    &:nth-child(2) {
                        margin: 0 toRem(60);
                    }
                }
            }
        }
        .info {
            margin-top: toRem(48);
            padding: 0 toRem(12);
            .infoItem {
                display: flex;
                font-size: toRem(14);
                margin-bottom: toRem(15);
                justify-content: space-between;
                div:nth-child(1) {
                    color: #999;
                }
                div:nth-child(2) {
                    color: #333;
                    font-weight: bold;
                }
                &:nth-child(6) {
                    img {
                        width: toRem(40);
                        height: toRem(40);
                        border-radius: 50%;
                    }
                }
                &:nth-child(7),&:nth-child(9) {
                    flex-direction: column;
                    height: toRem(180);
                    div:nth-child(1) {
                        margin-bottom: toRem(16);
                    }
                }
            }
        }
        .remark {
            font-size: toRem(14);
            color: #ff5353;
            padding: 0 toRem(12);
            margin-top: toRem(40);
            line-height: toRem(20);
        }
        .reset {
            height: toRem(40);
            border-radius: toRem(25);
            background: #ff5a01;
            font-size: toRem(14);
            color: #fff;
            font-weight: bold;
            text-align: center;
            line-height: toRem(40);
            margin-top: toRem(12);
            margin-bottom: toRem(30);
        }
    }
}
