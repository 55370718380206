@function toRem($a) {
    @return ($a/37.5) + rem;
}
.footer {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    z-index: 10;
    width: toRem(375);
    height: toRem(55);
    display: flex;
    font-size: toRem(12);
    background-color: #1F212E;
    border-radius: toRem(15) toRem(15) 0px 0px;
    div {
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1;
        flex-direction: column;
        align-items: center;
        color: #76767B;
        img {
            width: toRem(24);
            height: toRem(24);
        }
    }
}
