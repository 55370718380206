@function toRem($a) {
    @return ($a/37.5) + rem;
}

.content {
    overflow: hidden;
    color: #222;
    padding-bottom: toRem(70);
    background: #080C15;
    .index_bg{
        position: fixed;
        top: 0;
        left: 50%;
        width: toRem(375);
        transform: translateX(-50%);
        height:  toRem(258);
    }
    .main{
        position: relative;
        z-index: 2;
    }
    .header {
        background-size: 100% 100%;
        padding: 0 toRem(14);
        overflow: hidden;

        .title {
            display: flex;
            align-items: center;
            margin: toRem(22) 0;

            div:nth-child(1) {
                font-size: toRem(21);
                font-family: Source Han Sans SC;
                font-weight: bold;
                color: #F2F2F2;
                margin-right: toRem(10);
            }

            .logotext{
                font-size:toRem(12);
                font-family: Adobe Heiti Std;
                font-weight: normal;
                color: #F2F2F2;
            }
            .myshop{
                display: flex;
                align-items: center;
                .myshop_icon{
                    width: toRem(15);
                    height: toRem(15);
                    margin-right: toRem(5);
                }
                div{
                    font-size: toRem(15);
                    font-family: Source Han Sans CN-Bold, Source Han Sans CN;
                    font-weight: bold;
                    color: #FF5A01;
                }
            }
        }

        .banner {
            margin-bottom: toRem(23/2);
            .img {
                position: relative;
                height: toRem(125);
                width: 100%;
            }
            img {
                height: toRem(125);
                width: 100%;
                border-radius: toRem(8);
            }
        }

        .notice {
            background: #fff;
            border-radius: toRem(8);
            margin-bottom: toRem(11.5);
            display: flex;
            height: toRem(45);
            padding: 0 toRem(15);
            align-items: center;
            justify-content: space-between;

            .left {
                display: flex;

                div:nth-child(1) {
                    color: #222;
                    font-size: toRem(15);
                    font-weight: bold;
                }

                div:nth-child(2) {
                    position: relative;
                    right: toRem(14);
                    width: toRem(107/2);
                    background-size: 100% 100%;
                    color: #fff;
                    font-weight: bold;
                    font-style: italic;
                    font-size: toRem(14);
                    padding-left: toRem(16);
                    box-sizing: border-box;
                }
            }

            .right {
                flex: 1;
                color: #222;
                font-size: toRem(12);
            }
        }
    }

    .center {
        padding: 0 toRem(14);

        .goApplets {
            margin-bottom: toRem(10);
            display: flex;
            justify-content: space-between;
    
            div {
                background-size: 100% 100%;
                display: flex;
                flex-direction: column;
                box-sizing: border-box;
                justify-content: space-between;
            }
    
           .goApplets_wifi{
                width: toRem(231);
                height: toRem(83);
                padding: toRem(12) toRem(30);
                position: relative;
                
                .goApplets_wifi_1{
                    font-size: toRem(15);
                    font-family: PingFang SC;
                    font-weight: 800;
                    color: #FBFBFB;
                }
                .goApplets_wifi_2{
                    font-size: toRem(9);
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #FBFBFB;
                }
    
                img {
                    width: toRem(12);
                    height: toRem(12);
                }
            }
    
           .goApplets_food {
                padding: toRem(12) toRem(13);
                width: toRem(112);
                height: toRem(83);
                position: relative;
    
                .goApplets_food_1{
                    font-size: toRem(15);
                    font-family: PingFang SC;
                    font-weight: 800;
                    color: #FBFBFB;
                }
                .goApplets_food_2{
                    font-size: toRem(9);
                    font-family: PingFang SC;
                    font-weight: 500;
                    color: #FBFBFB;
                }
    
                img {
                    width: toRem(12);
                    height: toRem(12);
                }
            }
        }
    

        .pay-local {
            display: flex;
            margin-bottom: toRem(23/2);

            .pay {
                height: toRem(351/2);
                margin-right: toRem(23/2);
                width: toRem(336/2);
                background-size: 100% 100%;
                box-sizing: border-box;
                padding-left: toRem(25/2);
                padding-top: toRem(20);

                div:nth-child(1) {
                    font-size: toRem(16);
                    color: #222;
                    font-weight: bold;
                    margin-bottom: toRem(6.5);
                }

                div:nth-child(2) {
                    font-size: toRem(11);
                    color: #666;
                    margin-bottom: toRem(12);
                }

                div:nth-child(3) {
                    width: toRem(65);
                    height: toRem(20);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #718cfb;
                    border-radius: toRem(10);

                    span:nth-child(1) {
                        font-size: toRem(10);
                        color: #fff;
                        margin-right: toRem(2.5);
                    }

                    span:nth-child(2) {
                        background: #fff;
                        border-radius: toRem(15/2);
                        width: toRem(46/2);
                        height: toRem(30/2);
                        color: #718cfb;
                        font-size: toRem(10);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        font-weight: bold;
                    }
                }
            }

            .local {
                flex: 1;
                height: toRem(351/2);
                padding: toRem(17.5) toRem(14);
                border-radius: toRem(8);
                box-sizing: border-box;
                background-color: #fff;
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .left {
                    height: toRem(65);
                    width: toRem(65);
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-right: toRem(19/2);

                    div:nth-child(1) {
                        font-size: toRem(11);
                        color: #ff1607;
                    }

                    div:nth-child(2) {
                        font-size: toRem(14);
                        font-weight: bold;
                    }

                    div:nth-child(3) {
                        width: toRem(65);
                        height: toRem(20);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        background: #ff1607;
                        border-radius: toRem(10);

                        span:nth-child(1) {
                            font-size: toRem(10);
                            color: #fff;
                            margin-right: toRem(2.5);
                        }

                        span:nth-child(2) {
                            background: #fff;
                            border-radius: toRem(15/2);
                            color: #ff1607;
                            font-size: toRem(10);
                            display: flex;
                            width: toRem(46/2);
                            height: toRem(30/2);
                            align-items: center;
                            justify-content: center;
                            font-weight: bold;
                        }
                    }
                }

                .right {
                    margin-bottom: toRem(19/2);

                    &:nth-child(3) {
                        margin-right: toRem(19/2);
                    }

                    &:nth-child(3),
                    &:nth-child(4) {
                        margin-bottom: 0;
                    }

                    img {
                        width: toRem(65);
                        height: toRem(65);
                        border-radius: toRem(8);
                    }
                }
            }
        }

        .locals {
            padding: toRem(14) toRem(17);
            border-radius: toRem(8);
            box-sizing: border-box;
            background-color: #fff;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            background: linear-gradient(180deg, #e2e7fb 0%, #ffffff 100%);
            margin-bottom: toRem(23/2);

            .left {
                height: toRem(65);
                width: toRem(65);
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin-right: toRem(34);

                div:nth-child(1) {
                    font-size: toRem(11);
                    color: #6582fb;
                }

                div:nth-child(2) {
                    font-size: toRem(14);
                    font-weight: bold;
                }

                div:nth-child(3) {
                    width: toRem(65);
                    height: toRem(20);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: #718cfb;
                    border-radius: toRem(10);

                    span:nth-child(1) {
                        font-size: toRem(10);
                        color: #fff;
                        margin-right: toRem(2.5);
                    }

                    span:nth-child(2) {
                        background: #fff;
                        border-radius: toRem(15/2);
                        color: #718cfb;
                        font-size: toRem(10);
                        display: flex;
                        width: toRem(46/2);
                        height: toRem(30/2);
                        align-items: center;
                        justify-content: center;
                        font-weight: bold;
                    }
                }
            }

            .right {
                img {
                    width: toRem(65);
                    height: toRem(65);
                    border-radius: toRem(8);
                }
            }
        }

        .service_coupon {
            display: flex;

            .service {
                background: #ffffff;
                border-radius: toRem(8) 0px 0px toRem(8);
                padding: toRem(17) toRem(17.5) toRem(11.5) toRem(17.5);
                flex: 1;

                .title {
                    position: relative;
                    margin-bottom: toRem(11);

                    div:nth-child(1) {
                        font-size: toRem(16);
                        font-weight: bold;
                        margin-bottom: toRem(4);
                    }

                    div:nth-child(2) {
                        font-size: toRem(12);
                        color: #999;
                    }
                }

                .drink {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: toRem(11.5);
                    .item {
                        width: toRem(44);
                        height: toRem(62);
                        background-color: #f6f9ea;
                        border-radius: toRem(5);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        padding-top: toRem(7);
                        box-sizing: border-box;
                        img {
                            width: toRem(30);
                            height: toRem(30);
                        }
                        .name {
                            width: 100%;
                            background-color: #fedeba;
                            font-size: toRem(9);
                            line-height: toRem(17);
                            text-align: center;
                            border-radius: 0 0 toRem(5) toRem(5);
                        }
                    }
                }

                .take {
                    .item {
                        display: flex;
                        align-items: center;

                        &:nth-child(1) {
                            margin-bottom: toRem(6);
                        }

                        img {
                            width: toRem(60);
                            height: toRem(60);
                        }

                        .name {
                            margin-left: toRem(9.5);
                            flex: 1;

                            p:nth-child(1) {
                                font-size: toRem(14);
                                font-weight: bold;
                                margin-bottom: toRem(2);
                            }

                            p:nth-child(2) {
                                font-size: toRem(11);
                                color: #999;
                            }
                        }
                    }
                }
            }

            .coupon_goods {
                background: #e6ebfc;
                background-size: 100% 100%;
                flex: 1;
                border-radius: 0px toRem(8) toRem(8) 0px;
                padding: toRem(17) toRem(17.5) toRem(11.5) toRem(17.5);

                .title {
                    margin-bottom: toRem(11);

                    div:nth-child(1) {
                        font-size: toRem(16);
                        font-weight: bold;
                        margin-bottom: toRem(4);
                        // color: #ff485e;
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        div {
                            display: flex;
                            align-items: center;
                        }

                        img {
                            width: toRem(37);
                            height: toRem(13);
                        }
                    }

                    div:nth-child(2) {
                        font-size: toRem(12);
                        color: #999;
                    }
                }

                .goods {
                    background-size: 100% 100%;
                    height: toRem(188/2);
                    display: flex;
                    box-sizing: border-box;
                    margin-bottom: toRem(5);

                    .item {
                        width: toRem(139/2);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: center;
                        position: relative;
                        padding-top: toRem(6);

                        img {
                            width: toRem(55);
                            height: toRem(40);
                            margin-top: toRem(10);
                        }

                        .price {
                            margin-top: toRem(6);
                            font-size: toRem(14);
                            color: #000;
                            font-weight: bold;
                        }

                        .zhekou {
                            position: absolute;
                            top: 0;
                            width: toRem(27);
                            height: toRem(15);
                            border-radius: toRem(5) 0px toRem(5) 0px;
                            background: linear-gradient(90deg, #fecb30, #fb9221);
                            color: #fff;
                            text-align: center;
                            line-height: toRem(15);
                            font-size: toRem(8);
                        }

                        &:nth-child(1) .zhekou {
                            left: 0;
                            border-radius: toRem(5) 0px toRem(5) 0px;
                        }

                        &:nth-child(2) .zhekou {
                            right: 0;
                            border-radius: 0px toRem(5) 0px toRem(5);
                        }
                    }
                }

                .coupon {
                    background-size: 100% 100%;
                    height: toRem(188/2);
                    display: flex;
                    box-sizing: border-box;

                    .item {
                        width: toRem(139/2);
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        justify-content: space-between;
                        padding: toRem(12) 0 toRem(14) 0;
                        box-sizing: border-box;

                        div:nth-child(1) {
                            color: #000;
                            font-weight: bold;

                            span:nth-child(1) {
                                font-size: toRem(10);
                            }

                            span:nth-child(2) {
                                font-size: toRem(22);
                            }
                        }

                        div:nth-child(2) {
                            font-size: toRem(11);
                            color: #999;
                        }

                        div:nth-child(3) {
                            background-size: 100% 100%;
                            width: toRem(50);
                            height: toRem(21);
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            color: #fff;
                            font-size: toRem(11);
                        }
                    }
                }
            }
        }

        .goods_list {
            .title {
                display: flex;
                align-items: center;
                font-size: toRem(14);
                font-weight: bold;
                justify-content: center;
                color: #ff1607;
                margin: toRem(18) 0;

                span {
                    margin: 0 toRem(12);
                }

                img {
                    width: toRem(68.8);
                    height: toRem(11.51);
                }
            }
            .c_coupon{
                height:toRem(25);
                border-radius: toRem(7);
                border: toRem(1) solid #FB3C49;
                padding: 0 toRem(11);
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: toRem(10);
                .c_coupon_text{
                    font-size: toRem(13);
                    color: #FF3030;
                }
                .c_coupon_line{
                    width: 1px;
                    height: toRem(9);
                    background-color: #FB3C49;
                    margin: 0 toRem(10);
                }
            }
            
            .category{
                :global(.adm-tabs-header-mask-right) {
                    background: transparent;
                }
                :global(.adm-tabs-header-mask-left) {
                    background: transparent;
                }
                :global(.adm-tabs-header) {
                    border-bottom: none;
                }
            }

            .list {
                display: flex;
                justify-content: space-between;

                .left,
                .right {
                    width: toRem(170);

                    .item {
                        margin-bottom: toRem(7);
                        background-color: #fff;
                        border-radius: toRem(8);
                        padding-bottom: toRem(11);

                        img {
                            width: 100%;
                            height: toRem(170);
                            border-radius: toRem(8) toRem(8) 0 0;
                        }

                        .goods_name {
                            font-size: toRem(13.5);
                            color: #333;
                            overflow: hidden;
                            padding: 0 toRem(12);
                            text-overflow: ellipsis;
                            display: -webkit-box;
                            -webkit-line-clamp: 2;
                            -webkit-box-orient: vertical;
                            margin: toRem(5) 0;
                        }

                        .goods_price {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            padding: 0 toRem(12);

                            span:first-child {
                                color: #fb3916;
                                font-weight: bold;
                                font-size: toRem(18);

                                &:before {
                                    content: "￥";
                                    font-size: toRem(12);
                                }
                            }

                            span:last-child {
                                font-size: toRem(12);
                                color: #ccc;
                            }
                        }
                    }
                }
            }
        }
    }

    .terraceList {
        background-color: #fff;
        margin-bottom: toRem(23/2);
        border-radius: toRem(8);
        display: flex;
        flex-direction: column;
        justify-content: center;
        box-sizing: border-box;
        padding-bottom: toRem(8);
        .top {
            display: flex;
            padding: 0 toRem(11);
            justify-content: space-between;
            margin-top: toRem(18);
            margin-bottom: toRem(30);

            .huafei {
                position: relative;
                width: toRem(73);
                height: toRem(73);
            }

            img {
                width: toRem(73);
                height: toRem(73);
            }
        }

        .bottom {
            display: flex;
            padding-left: toRem(14);
            flex-wrap: wrap;
            .item {
                flex: none;
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: toRem(12);
                position: relative;
                width: toRem(64);
                margin-bottom: toRem(20);
                div:nth-chidl(2) {
                    margin-top: toRem(6);
                }
                .title {
                    font-size: toRem(8);
                    color: #fff;
                    padding: toRem(1) toRem(8) toRem(3);
                    background: #e24146;
                    position: absolute;
                    z-index: 10;
                    left: 50%;
                    top: 0;
                    text-align: center;
                    border-radius: toRem(10) toRem(10) toRem(10) 0;
                    white-space: nowrap;
                    transform: scale(0.7) translate( toRem(-10), toRem(-20));
                }
                img {
                    height: toRem(32);
                    margin-bottom: toRem(5);
                    width: toRem(30);
                }
            }
        }
    }
}
