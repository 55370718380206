@function toRem($a) {
    @return ($a/37.5) + rem;
}
.content {
    padding: toRem(12) toRem(12) toRem(60);
    overflow: hidden;
    .banner {
        width: 100%;
        height: toRem(150);
        margin-top: toRem(10);
    }
    .intro {
        display: flex;
        padding: 0 toRem(10);
        margin: toRem(12) 0;
        justify-content: space-between;
        div {
            display: flex;
            align-items: center;
            font-size: toRem(12);
            color: #999;
            span {
                margin-left: toRem(4);
            }
        }
        div:nth-child(1) img {
            width: toRem(17.14);
            height: toRem(12);
        }
        div:nth-child(2) img {
            width: toRem(12);
            height: toRem(12);
        }
        div:nth-child(3) img {
            width: toRem(10.66);
            height: toRem(12);
        }
    }
    .formInfo {
        background-color: #fff;
        border-radius: toRem(12);
        padding: toRem(20) toRem(12);
        .formItem {
            padding-bottom: toRem(5);
            border-bottom: toRem(1) solid #f2f2f2;
            margin-bottom: toRem(23);
            .title {
                font-size: toRem(15);
                font-weight: bold;
                margin-bottom: toRem(15);
                color: #333;
                display: flex;
                .flex1{
                    flex:1;
                }
                span {
                    color: #ff5a01;
                }
                .stitle{
                    font-size: toRem(14);
                    font-weight: 400;
                    flex-shrink: 0;
                }
                .locationinput {
                    flex: 1;
                    padding: toRem(6) toRem(10);
                    background-color: #f5f7fa;
                    border: toRem(1) solid #dadbde;
                    border-radius:toRem(8);
                    margin-left: toRem(5);
                }
                .locationbtn {
                    width: toRem(60);
                    height:toRem(30);
                    background: #FF5A01;
                    border-radius: toRem(8);
                    line-height: toRem(30);
                    text-align: center;
                    color: #ffffff;
                    font-size: toRem(14);
                }
            }
            .put {
                input {
                    font-size: toRem(14);
                    color: #333;
                    &::-webkit-input-placeholder {
                        color: #ccc;
                    }
                }
                span{
                    font-size: toRem(14);
                }
            }
            .address {
                display: flex;
                justify-content: space-between;
                align-items: center;
                img {
                    width: toRem(13.33);
                    height: toRem(16);
                }
            }
            .addLogo {
                display: flex;
                padding: 0 toRem(22);
                justify-content: space-between;
                color: #333;
                font-size: toRem(14);
                .demonstration {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    img {
                        width: toRem(80);
                        height: toRem(80);
                        border-radius: 50%;
                        margin-bottom: toRem(10);
                    }
                }
                .upLogo {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    .add {
                        width: toRem(80);
                        height: toRem(80);
                        border-radius: 50%;
                        border: toRem(1) dashed #ccc;
                        display: flex;
                        flex-direction: column;
                        align-items: center;
                        font-size: toRem(13);
                        color: #ccc;
                        justify-content: center;
                        margin-bottom: toRem(10);
                        position: relative;
                        box-sizing: border-box;
                        input {
                            position: absolute;
                            top: 0;
                            width: 100%;
                            height: 100%;
                            z-index: 2;
                            opacity: 0;
                        }
                        img {
                            width: toRem(23);
                            height: toRem(22.16);
                            margin-bottom: toRem(2);
                        }
                    }
                    .logo {
                        width: toRem(80);
                        height: toRem(80);
                        border-radius: 50%;
                        position: relative;
                        margin-bottom: toRem(10);

                        img {
                            width: toRem(80);
                            height: toRem(80);
                            border-radius: 50%;
                        }
                        .close {
                            position: absolute;
                            top: toRem(-5);
                            right: toRem(-5);
                        }
                    }
                }
            }
            .business_license {
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
                height: toRem(180);
                border: toRem(1) dashed #ccc;
                border-radius: toRem(8);
                background-size: 100% 100%;
                .add_business_license {
                    font-size: toRem(13);
                    color: #ccc;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    position: relative;
                    img {
                        width: toRem(23);
                        height: toRem(22.16);
                        margin-bottom: toRem(2);
                    }
                    input {
                        position: absolute;
                        top: 0;
                        width: 100%;
                        height: 100%;
                        z-index: 2;
                        opacity: 0;
                    }
                }
                .close {
                    position: absolute;
                    z-index: 2;
                    top: toRem(-5);
                    right: toRem(-5);
                }
            }
        }
    }
    .btn {
        position: fixed;
        z-index: 6;
        left:50%;
        transform: translateX(-50%);
        bottom: toRem(15);
        width: toRem(351);
        height: toRem(40);
        background: #ff5a01;
        color: #fff;
        text-align: center;
        line-height: toRem(40);
        border-radius: toRem(25);
        font-weight: bold;
        font-size: toRem(14);
    }
}
